import React from "react";
import { Collapse } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import s from "./style.module.less";
import { STATUS } from "../../constants";

const { Panel } = Collapse;

const CheckItem = ({ testName, status, checks, description }) => {
  return (
    <div className={`${s.checkItem} ${s[status]}`}>
      <div className={s.checkHeader}>
        {status === STATUS.LOADING && (
          <LoadingOutlined className={s.loadingIcon} />
        )}
        {status === STATUS.SUCCESS && (
          <CheckCircleOutlined className={s.successIcon} />
        )}
        {status === STATUS.FAIL && (
          <CloseCircleOutlined className={s.failIcon} />
        )}
        <span className={s.checkName}>{description || testName}</span>
      </div>
      {checks && checks.length > 0 && (
        <Collapse className={s.checks} style={{ marginTop: "16px" }}>
          <Panel header={'Details'} collapsible={status === STATUS.LOADING ? 'disabled' : 'header'}>
            {checks.map((nestedCheck, idx) => (
              <CheckItem {...nestedCheck} key={idx} />
            ))}
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default CheckItem;
