import { Tooltip, Button } from "antd";

const CopyReport = ({ copyReport, ...restProps }) => {
  return (
    <Tooltip
      title="Copy JSON to contact support"
      destroyTooltipOnHide
      placement="left"
    >
      <Button onClick={copyReport} {...restProps}>
        Copy
      </Button>
    </Tooltip>
  );
};

export default CopyReport;
