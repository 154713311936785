import { STATUS } from "../constants";

// Recursive check runner
export const runCheckRecursively = async (check, path, updateResult) => {
  let result = {
    ...check,
    testName: check.testName,
    status: STATUS.LOADING,
    failureError: null,
    checks: [],
  };

  updateResult(result, path);

  try {
    // Recursively run nested checks, if they exist
    if (check.checks && check.checks.length > 0) {
      const nestedResults = await Promise.all(
        check.checks.map((nestedCheck, index) =>
          runCheckRecursively(
            nestedCheck,
            [...path, "checks", index],
            updateResult
          )
        )
      );
      result.checks = nestedResults;
      const firstFailedCheck = nestedResults.find(
        (nestedResult) => !allChecksPassed(nestedResult)
      );
      result = firstFailedCheck ? {
        ...result,
        status: STATUS.FAIL,
        failureError: firstFailedCheck?.error || "unknown",
      } : { ...result, status: STATUS.SUCCESS };
    } else {
      const res = await check.getPromise();
      result = { ...result, ...res };
      delete result.checks;
    }
  } catch (error) {
    result = {
      ...result,
      status: STATUS.FAIL,
      failureError: "Unexpected error occurred",
    };
  }

  updateResult(result, path);
  return result;
};

const allChecksPassed = (result) => {
  if (result.status !== STATUS.SUCCESS) {
    return false;
  }

  // Check if all nested checks passed (if any exist)
  if (result.checks && result.checks.length > 0) {
    return result.checks.every(allChecksPassed);
  }

  return true;
};

export const runAllChecks = async (checks, updateResult) => {
  const promises = checks.map((check, index) =>
    runCheckRecursively(check, [index], updateResult)
  );
  const results = await Promise.allSettled(promises);
  const resolvedResults = results
    .filter((result) => result.status === "fulfilled")
    .map((result) => result.value);

  return resolvedResults.every(allChecksPassed);
};
