import { useState } from "react";
import { Modal } from "antd";
import ModalFooter from "./ModalFooter";
import TroubleShootForm from "../TroubleShootForm";
import { checkValidation, emailValidation } from "../../utils/fieldValidation";
import s from "./SubmitReportModal.module.less";

const SubmitReportModal = (props) => {
  const {
    open,
    onModalClose,
    isSubmitting,
    submitReport,
    copyReport,
  } = props;
  const [email, setEmail] = useState("");
  const [clientName, setClientName] = useState("titan");
  const [otherClientName, setOtherClientName] = useState("");
  const [validationError, setValidationError] = useState({});

  const handleEmailInput = (e) => {
    setEmail(e?.target?.value);
    setValidationError({
      ...validationError,
      email: null,
    });
  };

  const handleEmailInputBlur = (e) => {
    setValidationError({
      ...validationError,
      email: emailValidation(email),
    });
  };

  const handleSelectClient = (value) => {
    setClientName(value);
  };

  const handleOtherClientName = (e) => {
    setOtherClientName(e?.target?.value);
  };

  const _submitReport = async () => {
    const validationObj = checkValidation(email);
    setValidationError(validationObj);
    for (const [value] of Object.values(validationObj)) {
      if (value) {
        return;
      }
    }
    await submitReport({email, client: clientName === 'other' ? otherClientName : clientName});
    onModalClose();
  }

  return (
    <Modal
      open={open}
      title="Submit report to Titan Support"
      className="submitReportModal"
      okText="Submit report"
      destroyOnClose
      onCancel={onModalClose}
      confirmLoading={isSubmitting}
      maskClosable={false}
      footer={
        <ModalFooter
          submitReport={_submitReport}
          isSubmitting={isSubmitting}
          onModalClose={onModalClose}
          copyReport={copyReport}
        />
      }
    >
      <TroubleShootForm
        email={email}
        clientName={clientName}
        otherClientName={otherClientName}
        validationError={validationError}
        handleEmailInput={handleEmailInput}
        handleEmailInputBlur={handleEmailInputBlur}
        handleSelectClient={handleSelectClient}
        handleOtherClientName={handleOtherClientName}
      />
    </Modal>
  );
};

export default SubmitReportModal;
