import {
  DESCRIPTIONS,
  DNS_API_EUC1_TITAN_EMAIL,
  DNS_API_FLOCKMAIL_COM,
  DNS_APP_NEO_SPACE,
  DNS_APP_TITAN_EMAIL,
  DNS_NEO_SPACE,
  GOOGLE_DNS,
  INTERNET_CONNECTION,
  LOCAL_DNS,
  NETWORK_ACCESSIBILITY,
  STATUS,
  TITAN_EU_SERVER_ACCESSIBILITY,
  TITAN_US_SERVER_ACCESSIBILITY,
  WEBSOCKET_EU_SERVER_ACCESSIBILITY,
  WEBSOCKET_US_SERVER_ACCESSIBILITY,
} from "../constants";
import {
  sleep,
  checkConnection,
  checkWebsocketConnection,
} from "../helpers/checkConnection.helper";
import {
  checkDNSStatus,
  checkDNSStatusUsingGoogle,
} from "../helpers/dns.helper";
import {
  API_FLOCK_MAIL,
  API_FLOCK_MAIL_EU,
  APP_NEO_SPACE,
  APP_TITAN_EMAIL,
  NEO_SPACE,
  internetCheckUrls,
  titanEUServerCheckUrls,
  titanUSServerCheckUrls,
  websocketEUServerUrl,
  websocketUSServerUrl,
} from "../services/urls";

const networkCheck = {
  testName: NETWORK_ACCESSIBILITY,
  description: "Network accessibility on your machine",
  getPromise: async () => {
    await sleep(1000);
    return {
      status: window.navigator?.onLine ? STATUS.SUCCESS : STATUS.FAIL,
    };
  },
};

const internetCheck = {
  testName: INTERNET_CONNECTION,
  description: 'Internet connection',
  getPromise: async () => {
    await sleep(1000);
    return checkConnection(internetCheckUrls);
  },
};

const titanUSServerCheck = {
  testName: TITAN_US_SERVER_ACCESSIBILITY,
  description: 'Titan server(US) connection',
  getPromise: async () => {
    await sleep(1000);
    return checkConnection(titanUSServerCheckUrls);
  },
};

const titanEUServerCheck = {
  testName: TITAN_EU_SERVER_ACCESSIBILITY,
  description: 'Titan server(EU) connection',
  isEu: true,
  getPromise: async () => {
    await sleep(1000);
    return await checkConnection(titanEUServerCheckUrls);
  },
};

const webSocketUSServerCheck = {
  testName: WEBSOCKET_US_SERVER_ACCESSIBILITY,
  description: 'Websocket server(US) connection',
  getPromise: async () => {
    await sleep(1000);
    return checkWebsocketConnection(websocketUSServerUrl);
  },
};

const webSocketEUServerCheck = {
  testName: WEBSOCKET_EU_SERVER_ACCESSIBILITY,
  description: 'Websocket server(EU) connection',
  isEu: true,
  getPromise: async () => {
    await sleep(1000);
    return checkWebsocketConnection(websocketEUServerUrl);
  },
};

const dnsCheck = {
  testName: LOCAL_DNS,
  description: 'Local DNS ',
  checks: [
    {
      testName: DNS_APP_TITAN_EMAIL,
      description: 'DNS for app.titan.email',
      getPromise: async () => {
        return await checkDNSStatus(APP_TITAN_EMAIL);
      },
    },
    {
      testName: DNS_API_FLOCKMAIL_COM,
      description: 'DNS for api.flockmail.com',
      getPromise: async () => {
        return await checkDNSStatus(API_FLOCK_MAIL);
      },
    },
    {
      testName: DNS_API_EUC1_TITAN_EMAIL,
      description: 'DNS for api-euc1.titan.email',
      getPromise: async () => {
        return await checkDNSStatus(API_FLOCK_MAIL_EU);
      },
    },
    {
      testName: DNS_APP_NEO_SPACE,
      description: 'DNS for app.neo.space',
      getPromise: async () => {
        return await checkDNSStatus(APP_NEO_SPACE);
      },
    },
    {
      testName: DNS_NEO_SPACE,
      description: 'DNS for neo.space',
      getPromise: async () => {
        return await checkDNSStatus(NEO_SPACE);
      },
    },
  ],
};

const googleDNSCheck = {
  testName: GOOGLE_DNS,
  description: 'Google DNS',
  checks: [
    {
      testName: DNS_APP_TITAN_EMAIL,
      description: 'DNS for app.titan.email',
      getPromise: async () => {
        return await checkDNSStatusUsingGoogle(APP_TITAN_EMAIL);
      },
    },
    {
      testName: DNS_API_FLOCKMAIL_COM,
      description: 'DNS for api.flockmail.com',
      getPromise: async () => {
        return await checkDNSStatusUsingGoogle(API_FLOCK_MAIL);
      },
    },
    {
      testName: DNS_API_EUC1_TITAN_EMAIL,
      description: 'DNS for api-euc1.titan.email',
      getPromise: async () => {
        return await checkDNSStatusUsingGoogle(API_FLOCK_MAIL_EU);
      },
    },
    {
      testName: DNS_APP_NEO_SPACE,
      description: 'DNS for app.neo.space',
      getPromise: async () => {
        return await checkDNSStatusUsingGoogle(APP_NEO_SPACE);
      },
    },
    {
      testName: DNS_NEO_SPACE,
      description: 'DNS for neo.space',
      getPromise: async () => {
        return await checkDNSStatusUsingGoogle(NEO_SPACE);
      },
    },
  ],
};
export const checks = [
  networkCheck,
  internetCheck,
  titanUSServerCheck,
  titanEUServerCheck,
  webSocketUSServerCheck,
  webSocketEUServerCheck,
  dnsCheck,
  googleDNSCheck,
];
