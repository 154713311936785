import axios from "axios";
import { STATUS, STATUS_CODES } from "../constants";

export const sleep = async (time) => {
  return new Promise((r) => setTimeout(r, time));
};

export const getUncachedUrl = (originalUrl) => {
  const url = new URL(originalUrl);
  url.searchParams.append("date", `${Date.now()}${Math.random()}`);
  return url.href;
};

export const checkWebsocketConnection = (websocketUrl) => {
  const ws = new WebSocket(websocketUrl);
  let wasOpened = false;

  const promise = new Promise((resolve) => {
    ws.onopen = () => {
      console.log("Websocket connected");
      wasOpened = true;
      resolve({ status: STATUS.SUCCESS, failureError: null });
      ws.close(); // Close after connection is confirmed
    };

    ws.onerror = (err) => {
      console.log("Websocket connection error:", err);
      resolve({
        status: STATUS.FAIL,
        failureError: err.message || "Unknown error",
      });
    };

    ws.onclose = (event) => {
      console.log("Websocket closed");
      if (wasOpened && event.wasClean) {
        resolve({ status: STATUS.SUCCESS, failureError: null });
      } else if (wasOpened) {
        resolve({
          status: STATUS.FAIL,
          failureError: `Connection closed unexpectedly: ${event.reason}`,
        });
      } else {
        resolve({
          status: STATUS.FAIL,
          failureError: "Connection failed before opening",
        });
      }
    };

    setTimeout(() => {
      if (!wasOpened) {
        resolve({
          status: STATUS.FAIL,
          failureError: "Connection timeout",
        });
        ws.close();
      }
    }, 10 * 1000);
  });

  return promise;
};

export const checkConnection = async (
  connectionCheckUrls,
  useOriginal = false
) => {
  try {
    const responses = await Promise.all(
      connectionCheckUrls.map((url) =>
        axios.get(useOriginal ? url : getUncachedUrl(url))
      )
    );
    const failedResponses = responses.filter(
      (res) => res.status < 200 || res.status >= 300
    );

    if (!failedResponses.length) {
      return {
        status: STATUS.SUCCESS,
        failureError: null,
        details: JSON.stringify({
          checkedUrls: connectionCheckUrls,
          statusCode: responses[responses.length - 1]?.status,
        }),
      };
    }
    const failedResponse = failedResponses[0];
    const failedUrls = failedResponses.map((res) => res.config.url);

    return {
      status: STATUS.FAIL,
      failureError: failedResponse?.data || "no data returned",
      details: JSON.stringify({
        checkedUrls: connectionCheckUrls,
        failedUrls,
        statusCode: failedResponse?.status,
      }),
    };
  } catch (error) {
    console.log(
      "error in check connection, (most likely network or DNS)",
      error
    );
    return {
      status: STATUS.FAIL,
      failureError: `network error (${error})`,
      details: JSON.stringify({
        statusCode: STATUS_CODES.networkError,
        checkedUrls: connectionCheckUrls,
      }),
    };
  }
};
