import React, { useState, useRef, useCallback, useEffect } from "react";
import SubmitReportModal from "../../components/SubmitReportModal";
import TroubleShooterReport from "../../components/TroubleShooterReport";
import { fetchIPAddress, postTroubleShootReport } from "../../services/titanTroubleShooterService";
import { runAllChecks } from "../../helpers/runChecks.helpers";
import { checks } from "../../constants/checks";
import set from "lodash/set";
import { TOAST_MSGS } from "../../constants/toastMsgs";
import { showToast } from "../../utils/toastUtils";
import s from "./TitanTroubleShooter.module.less";
import { STATES } from "../../constants";
import { filterNestedObjects, renameNestedArrayProp } from "../../utils/arrayUtils";

const TitanTroubleShooter = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitReportModalOpen, setIsubmitReportModalOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [state, setState] = useState(STATES.RUNNING);
  const ip = useRef("");
  const isReportSubmitted = useRef(false);

  useEffect(() => {
    runChecks();
  }, []);

  const updateResult = useCallback((newResult, pathToUpdate) => {
    setResult((prevResults) => {
      const updatedResults = [...prevResults];
      set(updatedResults, pathToUpdate, newResult);
      return updatedResults;
    });
  }, []);

  const runChecks = useCallback(async () => {
    setState(STATES.RUNNING);
    setResult([]);
    const allCheckPassed = await runAllChecks(checks, updateResult);
    setState(allCheckPassed ? STATES.SUCCESS : STATES.FAIL);
    setIsubmitReportModalOpen(!allCheckPassed);
  }, [updateResult]);

  const retry = useCallback(() => {
    runChecks();
    isReportSubmitted.current = false;
  }, [runChecks]);

  const handleOpenSubmitReportModal = () => {
    setIsubmitReportModalOpen(true);
  };

  const handleCloseSubmitReportModal = () => {
    setIsubmitReportModalOpen(false);
  };

  const fetchIp = async () => {
    ip.current = await fetchIPAddress();
  };

  useEffect(() => {
    fetchIp();
  }, []);

  const getReportData = () => {
    return {
    ip: ip.current,
    userAgent: navigator?.userAgent,
    PROD: {
      testResults: renameNestedArrayProp(filterNestedObjects(result, (r) => !r?.isEu), 'checks', 'testResults'),
    },
    PROD_EUC1: {
      testResults: renameNestedArrayProp(filterNestedObjects(result, (r) => r?.isEu), 'checks', 'testResults'),
    }
  }
  }

  const submitReport = async (data = {}) => {
    try {
      setIsSubmitting(true);
      const payload = {
        ...data,
       ...getReportData(),
      };
      console.log(payload);
      await postTroubleShootReport(payload);
      isReportSubmitted.current = true;
      showToast({ message: TOAST_MSGS.submitReport });
    } catch (e) {
      showToast({ message: TOAST_MSGS.apiError });
      throw new Error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const copyReport = () => {
    const report = getReportData();
    navigator.clipboard.writeText(JSON.stringify(report));
    showToast({ message: TOAST_MSGS.copied });
  };

  return (
    <div className={s.troubleShooter}>
      {
        <TroubleShooterReport
          result={result}
          handleOpenSubmitReportModal={handleOpenSubmitReportModal}
          isReportSubmitted={isReportSubmitted}
          retry={retry}
          state={state}
          copyReport={copyReport}
        />
      }
      <SubmitReportModal
        open={isSubmitReportModalOpen}
        onModalClose={handleCloseSubmitReportModal}
        isReportSubmitted={isReportSubmitted}
        isSubmitting={isSubmitting}
        submitReport={submitReport}
        copyReport={copyReport}
      />
    </div>
  );
};

export default TitanTroubleShooter;
