/**
 * Utility to extract the domain from a given URL.
 * @param {string} url - The URL to extract the domain from.
 * @returns {string} - The domain (hostname) of the URL.
 */
export function getDomainFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error("Invalid URL:", error.message);
    return null;
  }
}
