import axios from "axios";
import { STATUS } from "../constants";
import { isConnectionSuccessful } from "../utils/commonUtils";
import { getDomainFromUrl } from "./domain.helper";

/**
 * Utility to check DNS status of a URL by attempting an HTTP request.
 * @param {string} url - The URL to check.
 * @returns {Promise<Object>} - A promise resolving with DNS status information.
 */
export async function checkDNSStatus(url) {
  try {
    const response = await fetch(url, { mode: "no-cors" });

    if (isConnectionSuccessful(response.status)) {
      return {
        status: STATUS.SUCCESS,
        statusCode: response.status,
        details: JSON.stringify({
          message: "The URL is reachable and responded successfully.",
        }),
      };
    } else {
      return {
        status: STATUS.SUCCESS,
        statusCode: response.status,
        details: JSON.stringify({
          message: `The URL responded but with status code ${response.status}.`,
        }),
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: STATUS.SUCCESS,
        statusCode: error?.response?.status,
        details: JSON.stringify({
          message: `The URL responded with status code ${error?.response?.status}.`,
        }),
      };
    } else if (error.request) {
      return {
        status: STATUS.FAIL,
        failureError: "The URL could not be reached. No response received.",
      };
    } else {
      return {
        status: STATUS.FAIL,
        failureError: `An error occurred while checking the URL: ${error?.message}`,
      };
    }
  }
}

/**
 * Utility to check DNS status of a URL using Google's DNS-over-HTTPS service (8.8.8.8).
 * @param {string} domain - The domain to check.
 * @returns {Promise<Object>} - A promise resolving with DNS status information.
 */
export async function checkDNSStatusUsingGoogle(url) {
  const googleDNSApi = "https://dns.google/resolve";

  try {
    const domain = getDomainFromUrl(url);
    const response = await axios.get(googleDNSApi, {
      params: {
        name: domain,
      },
    });

    if (response?.data?.Status === 0) {
      return {
        status: STATUS.SUCCESS,
        details: JSON.stringify({
          ...(response?.data || {}),
        }),
      };
    } else {
      return {
        status: STATUS.FAIL,
        failureError: `The domain could not be resolved. Status code: ${response?.data?.Status}`,
        details: JSON.stringify({
          ...(response?.data || {}),
        }),
      };
    }
  } catch (error) {
    return {
      status: STATUS.FAIL,
      failureError: `An error occurred while checking the DNS status: (most likely network) ${error?.message}`,
    };
  }
}
