import { Button } from "antd";
import CopyResult from "../CopyResult";

const ModalFooter = ({ submitReport, isSubmitting, copyReport }) => {
  const sendReport = async () => {
    await submitReport();
  };

  return (
    <div>
      <CopyResult copyReport={copyReport} />
      <Button onClick={sendReport} type="primary" loading={isSubmitting}>
        Submit report
      </Button>
    </div>
  );
};

export default ModalFooter;
