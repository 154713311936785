import React, { useState, useEffect } from "react";
import { Button } from "antd";
import CopyResult from "../CopyResult";
import s from "./style.module.less";
import CheckItem from "../CheckItem";
import { STATES } from "../../constants";

export default function TroubleShooter({
  result,
  handleOpenSubmitReportModal,
  isReportSubmitted,
  state,
  retry,
  copyReport,
}) {
  const disabled = state === STATES.RUNNING;

  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        <h2 className={s.title}>Diagnosis report</h2>
        <div className={s.buttonWrapper}>
          <CopyResult
            copyReport={copyReport}
            className={s.copy}
            disabled={disabled}
          />
          {
            <Button
              className={s.contactSupport}
              type="primary"
              onClick={handleOpenSubmitReportModal}
              disabled={disabled}
            >
              Submit report
            </Button>
          }
        </div>
      </div>
      <div className={s.reportCard}>
        <div className={s.reportHeader}>
          <span>
            {state === STATES.RUNNING
              ? "Checking for problems..."
              : state === STATES.SUCCESS
              ? "No Problems found"
              : "Problems found"}
          </span>
          <Button onClick={retry} disabled={disabled}>
            Retry
          </Button>
        </div>
        <Reports result={result} />
        <div className={s.reportFooter}>Logs attached</div>
      </div>
    </div>
  );
}

function Reports({ result }) {
  return (
    <div className={s.reports}>
      <div className={s.checksList}>
        {result.map((check, idx) => (
          <CheckItem key={idx} {...check} />
        ))}
      </div>
    </div>
  );
}
