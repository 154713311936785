import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';

export function filterNestedObjects(arr, predicate) {
  return map(arr, (item) => {
    // Check if the current item has any array properties and recursively process only arrays
    if (isArray(item)) {
      // Recursively process arrays
      return filterNestedObjects(item, predicate);
    }

    // Apply the predicate to filter the object
    return predicate(item) ? item : null;
  }).filter(Boolean); // Remove nulls from filtered results
}

export const renameNestedArrayProp = (obj, targetProp, newProp) => {
  if (isArray(obj)) {
    return obj.map(item => renameNestedArrayProp(item, targetProp, newProp));
  }

  if (isObject(obj)) {
    return reduce(obj, (result, value, key) => {
      if (isArray(value)) {
        // If this property is the one we're targeting and it's an array, rename it
        if (key === targetProp) {
          result[newProp] = renameNestedArrayProp(value, targetProp, newProp);
        } else {
          result[key] = renameNestedArrayProp(value, targetProp, newProp);
        }
      } else {
        result[key] = renameNestedArrayProp(value, targetProp, newProp);
      }
      return result;
    }, {});
  }

  // If it's neither an array nor an object, just return it as is
  return obj;
};