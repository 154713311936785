export const STATUS_CODES = {
  success: 200,
  badRequest: 400,
  networkError: 0,
};

export const STATUS = {
  LOADING: "loading",
  SUCCESS: "Success",
  FAIL: "Failure",
};

export const STATES = {
  RUNNING: "running",
  SUCCESS: "Success",
  FAIL: "Failure",
};

export const NETWORK_ACCESSIBILITY = "NETWORK_ACCESSIBILITY";
export const INTERNET_CONNECTION = "INTERNET_CONNECTION";
export const TITAN_US_SERVER_ACCESSIBILITY = "TITAN_SERVER_ACCESSIBILITY";
export const TITAN_EU_SERVER_ACCESSIBILITY = "TITAN_SERVER_ACCESSIBILITY";
export const WEBSOCKET_US_SERVER_ACCESSIBILITY =
  "WEBSOCKET_SERVER_ACCESSIBILITY";
export const WEBSOCKET_EU_SERVER_ACCESSIBILITY =
  "WEBSOCKET_SERVER_ACCESSIBILITY";
export const LOCAL_DNS = "LOCAL_DNS";
export const GOOGLE_DNS = "GOOGLE_DNS";
export const DNS_APP_TITAN_EMAIL = "DNS_APP_TITAN_EMAIL";
export const DNS_API_FLOCKMAIL_COM = "DNS_API_FLOCKMAIL_COM";
export const DNS_API_EUC1_TITAN_EMAIL = "DNS_API_EUC1_TITAN_EMAIL";
export const DNS_APP_NEO_SPACE = "DNS_APP_NEO_SPACE";
export const DNS_NEO_SPACE = "DNS_NEO_SPACE";

export const DESCRIPTIONS = {
  [NETWORK_ACCESSIBILITY]: {
    [STATUS.LOADING]: "Checking machine’s network access...",
    [STATUS.SUCCESS]: "Network accessibility on your machine",
    [STATUS.FAIL]: "Network accessibility on your machine failed",
  },
  [INTERNET_CONNECTION]: {
    [STATUS.LOADING]: "Checking internet connection...",
    [STATUS.SUCCESS]: "Internet connection ",
    [STATUS.FAIL]: "Internet connection failed",
  },
  [TITAN_US_SERVER_ACCESSIBILITY]: {
    [STATUS.LOADING]: "Checking Titan server(US)...",
    [STATUS.SUCCESS]: "Titan server(US) connection",
    [STATUS.FAIL]: "Titan server(US) connection failed",
  },
  [TITAN_EU_SERVER_ACCESSIBILITY]: {
    [STATUS.LOADING]: "Checking Titan server(EU)...",
    [STATUS.SUCCESS]: "Titan server(EU) connection",
    [STATUS.FAIL]: "Titan server(EU) connection failed",
  },
  [WEBSOCKET_US_SERVER_ACCESSIBILITY]: {
    [STATUS.LOADING]: "Checking Websocket server(US)...",
    [STATUS.SUCCESS]: "Websocket server(US) connection",
    [STATUS.FAIL]: "Websocket server(US) connection failed",
  },
  [WEBSOCKET_EU_SERVER_ACCESSIBILITY]: {
    [STATUS.LOADING]: "Checking Websocket server(EU)...",
    [STATUS.SUCCESS]: "Websocket server(EU) connection",
    [STATUS.FAIL]: "Websocket server(EU) connection failed",
  },
};

export const CLIENT_TYPE_OPTIONS = [
  { value: "titan", label: "Titan" },
  { value: "outlook", label: "Outlook" },
  { value: "thunderbird", label: "Thunderbird" },
  { value: "other", label: "Other" },
];
