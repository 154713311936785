export const URLS = {
  submitReportUSServer: "https://network-diagnostics.titan.email/",
  submitReportEUServer: "https://network-diagnostics-eu.titan.email/",
  fetchIpAddressUrl: "https://api.ipify.org?format=json",
};

export const internetCheckUrls = ["https://picsum.photos/200/300?random=1"];
export const titanUSServerCheckUrls = ["https://api.flockmail.com/fa/status"];
export const titanEUServerCheckUrls = [
  "https://api-euc1.titan.email/fa/status",
];
export const websocketUSServerUrl =
  "wss://api.flockmail.com/ae/ws/create?st=networkDiagnosticST";
export const websocketEUServerUrl =
  "wss://api-euc1.titan.email/ae/ws/create?st=networkDiagnosticST";

export const TITAN_EMAIL = 'https://titan.email';
export const APP_TITAN_EMAIL = "https://app.titan.email"
export const API_FLOCK_MAIL = "https://api.flockmail.com"
export const API_FLOCK_MAIL_EU = "https://api-euc1.titan.email"

export const APP_NEO_SPACE = "https://app.neo.space"
export const NEO_SPACE = "https://neo.space"